html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}


body {
  --colors-theme: #237fb1;
  --colors-secondary: #f58220;
  --colors-orange: #f58220;
  --colors-yellow: #ffdf35;
  --colors-green: #8dc63f;
  --colors-blue: #2c9fdd;
  --colors-red: #ab3030;
  --colors-navigation: #7c9eb1;
  --colors-background: #fff;
  --colors-text: #333;
  --colors-disabledText: #999;
  --colors-border: #c4c4c4;
  --colors-disabled: #ddd;
  --colors-disabledBackground: #eee;
  --colors-hover: #ddd;
  --colors-dragTarget: #bbb;
  --colors-selected: #ccc;
  --colors-errorBackground: #fcd6d6;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

